.tox.tox-tinymce {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-gray-300);
}

.tox-tinymce_error .tox-tinymce {
  border: 1px solid #f14c41 !important;
}

.tox-tinymce_focus .tox-tinymce {
  border-color: var(--bs-primary);
}

.hook-form-editors_skeleton {
  height: 500px !important;
  width: 100% !important;
}
