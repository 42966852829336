.sapp-dashboard--body {
  font-size: 20px;
  line-height: 25px;
  color: #a1a1a1;
  text-align: center;
}

.sapp-text-coming {
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 25px;
}

.sapp-text-gray {
  color: #a1a1a1;
}

.sapp-text-gray-800 {
  color: #404041;
}

@keyframes loading {
  0% {
    max-width: 0;
  }
}

.sapp-dashboard {
  height: 85vh;
}

.sapp-shadow {
  box-shadow: 0px 2px 4px 0px #00000014, 0px 0px 6px 0px #00000005;
}

.card.sapp-dashboard {
  border: none;
}