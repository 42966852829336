.sapp-tab-btn {
    font-weight: 400;
    font-size: 18px;
    font-family: Inter;
    padding: 17px 36px 17px 36px;
    line-height: 15.99px;
    color: #7E8299
        /* 88.833% */
}

.sapp-text-active {
    font-weight: 500;
    color: var(--bs-primary) !important;
    border-bottom: 1px solid var(--bs-primary) !important;
}

.sapp-inputname-wrapper {
    // padding: 27px 0 27px 0;
    border-bottom: 1px dashed #E1E3EA;
}

.sapp-doc-input-wrapper {
    border-top: 1px dashed #E1E3EA;

}

.sapp-input-tab {

    min-height: 48px;
}

.sapp-addbtn-wrapper {
    border: 1px solid #8F8F8F;
    border-radius: 9px;
}

.sapp-footer-create-doc {
    padding: 2rem 0;
    border-top: 1px solid #F4F4F4
}

.sapp-custom-card {
    min-height: 48px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.sapp-tab-content-wrapper {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}