.sapp-required-dot {
    color: #F14C41;
    // font-family: Roboto;
    font-size: 13.975px;
    font-style: normal;
    font-weight: 600;
    line-height: 20.962px;
    margin-left: 2px
        /* 150% */
}

.sapp-addVideo-area {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    border: 1px solid #E1E3EA;
    background: #FFF;
    box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.03);

    &.video-selected {
        justify-content: unset;
    }

    .sapp-addVideo-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
    }
}

.sapp-video-selected-container {
    width: 100%;
    height: 579px;
    overflow: hidden;
    flex-direction: column;
}

.sapp-tab-btn-video {
    width: 79px;
    height: 79px;

    &:hover {
        .sapp-icon-btn-tab {
            color: var(--bs-primary) !important
        }

        .sapp-title-video-setting {
            color: var(--bs-primary);
        }
    }

    &.active {
        .sapp-title-video-setting {
            color: var(--bs-primary);
        }
    }
}

.sapp-icon-btn-tab {
    color: #CCCED6 !important;

}

.sapp-title-video-setting {
    color: #9094A5;
    text-align: center;
    // font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    /* 109.091% */
    letter-spacing: 0.115px;

}

.sapp-video-setting-content-container {
    padding-left: 80px;
}

.sapp-added-question-container {
    display: flex;
    width: 35%;
    background: white;
    border-right: 1px solid #E1E3EA;
    border-bottom: 1px solid #E1E3EA;

    .sapp-title-video-setting {
        display: flex;
    }
}

.sapp-list-question-added-container {
    max-height: 70%;
    overflow: auto;
}

.sapp-input-menuVideo {
    border-top: none;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    padding: 0 !important;
}

.sapp-question-name {
    max-width: 100%;
    // overflow: hidden;
    color: var(--text-color-primary, #141414);

    // text-overflow: ellipsis;
    // white-space: nowrap;
    // font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    /* 27.2px */
}

.sapp-text-time {
    color: #9094A5;
    // font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.1px;
    /* 115% */
}

.sapp-questionTab-title {
    color: var(--text-color-primary, #141414);
    // font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 133.333% */
}

.sapp-tabside-container {
    border-right: 1px solid #E1E3EA;
}

.sapp-video-container {
    // display: flex;
    padding: 10px;
    width: 70%;
    // border-bottom:  1px solid #E1E3EA;
    background: #F7F7F8;

    .ui-video-seek-slider .track .main .connect {
        background-color: var(--bs-primary);
    }

    .ui-video-seek-slider .thumb .handler {
        background-color: var(--bs-primary);

    }
}

.sapp-video-uploaded-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.sapp-modal-body-cutsom {
    .sapp-modal-body-content {
        height: 100%;
    }
}

.sapp-title-alert {
    color: #141414;
    // font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 114.286% */
}

.sapp-content-alert {
    color: #7E8299;
    // font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    /* 16.9px */
}