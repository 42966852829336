.sapp-course-content-tree-first,
.sapp-course-content-tree-second {
  display: flex;
  align-items: end;
  flex-direction: column;

  .ant-skeleton.ant-skeleton-element:first-child {
    width: 100%;
    padding: 12px 0;
  }

  @for $i from 2 through 10 {
    .ant-skeleton.ant-skeleton-element:nth-child(#{$i}) {
      width: calc(100% - #{$i * 2%});
      display: block;
      padding: 12px 0;
    }
  }

  .ant-skeleton .ant-skeleton-input {
    height: 48px;
    width: 100%;
  }
}
