.sapp-learning-selected-container {
    padding: 0 12px 0 12px;
    background: white;
    border-radius: 8px;
    min-height: 48px;
    border: 1px solid var(--gray-gray-300, #E1E3EA);
    display: flex;
    align-items: center;
    justify-content: space-between;
        .sapp-selected-name {
            color:  #141414;
            // font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            /* 114.286% */
        }
}
.sapp-no-wrap-text-trunscate {
    white-space: nowrap;
    // max-width: 60%;
    display:unset
}