.sapp-mw-220px {
  max-width: 220px;
}

.sapp-height-45px {
  height: 45px;
}

.sapp-mw-215px {
  max-width: 215px;
}
.sapp-tag-form-custom{
  min-height: 45px;
}
.sapp-checkbox-text-custom {
  color:  #141414 !important;
  // font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */}
