.sapp-button-dropdown {
  .dropdown-item {
    font-size: 14px;
    line-height: 1.5714285714285714;
    padding: 0.5rem 12px 7.15px 12px;
    cursor: pointer;
    min-height: 32px;
    color: rgba(0, 0, 0, 0.88);

    &:hover {
      color: var(--bs-primary) !important;
      background-color: rgba(255, 187, 0, 0.0705882353) !important;
      font-weight: 500;
      transition: color 0.2s ease;
    }
  }
}
