.modal-dialog:not(.modal-fullscreen) {
  border-left: 1.5rem solid transparent;
  border-right: 1.5rem solid transparent;
}
.modal-fullscreen.modal-fullscreen-form {
  .modal-header {
    padding: 0 !important;
    max-width: 1200px;
    margin: auto;
    width: 100%;

    & > .sapp-modal-header-title {
      padding: 1.75rem 0 !important;
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      font-size: 18px;
      color: #141414;
      font-weight: 600;
      line-height: 18px;
      /* 100% */
    }

    .sapp-btn-close-modal {
      position: absolute;
      right: 0;
      padding: var(--bs-modal-header-padding) !important;
    }
  }

  .modal-header {
    transform: translateX(-0px);
  }

  .modal-body {
    padding: 0 !important;
    display: block;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;

    & > div {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin: 0 !important;
      margin-right: 0 !important;
    }

    & .sapp-modal-body-content {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      padding: var(--bs-modal-header-padding) !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .modal-footer {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: var(--bs-modal-header-padding) !important;
  }
}

.sapp-drawer-title {
  color: #141414;

  /* Body/B-18-18-600 */
  // font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 100% */
  letter-spacing: -0.18px;
}

.sapp-btn-close-modal {
  position: relative;
  bottom: 3px;
  right: 0px;
}
