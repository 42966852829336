.sapp-collapse-wrapper{
    svg{
        color:#E1E3EA
    }
    /* Set the initial transform of the element */
    transform: rotate(0deg);
    /* Set the transition duration and timing function of the element */
    transition: 0.25s ease-in-out;
    &.expanded {
        transform: rotate(90deg);
        svg{
            color: var(--bs-primary)
        }
    }
}
.sapp-parent-wrapper {
    max-height:47px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}