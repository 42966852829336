.sapp-class-title-name {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.sapp-class-icon {
  border-radius: 4px;
  padding: 7px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  color: #fff;
  text-transform: capitalize;
  width: max-content;
}

.sapp-class-icon-public {
  background-color: #50CD89;
}

.sapp-class-icon-draft {
  background-color: #FFB800;
}

.sapp-class-icon-blocked {
  background-color: #D35563;
}

.sapp-class-icon-ended {
  background-color: #7239EA;
}

.sapp-icon-last {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  &.first {
    right: 56px;
  }

  &.last {
    right: 48px;
  }
}

.sapp-table-class-field {
  color: #141414 !important;
  font-weight: 500 !important;

  &.createDateRangePicker {
    input {
      color: #141414 !important;
      font-weight: 500 !important;
      font-size: 14px;
      line-height: 14px;
    }
    input:hover {
      background-color: transparent !important;
      border-color: initial !important;
    }
  }

  .ant-select-selection-item {
    color: #141414 !important;
    font-weight: 500 !important;
  }
}

.sapp-text-gray-detail {
  color: #3F4254;
}

.sapp-content-profile {
  &:hover {
    img,
    video {
      cursor: pointer;
    }
  }
}

.sapp-color-percent {
  color: #50CD89 !important;
}
.sapp-color-parti {
  color: #50CD89 !important;
  background-color: #EEF6FF !important;
}

.sapp-user-icon {
  padding: 4px 7px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  border-radius: 2px;
}

.sapp-user-icon-normal {
  display: none;
  /* background-color: #FFFAF0;
  color: #FFB800; */
}

.sapp-user-icon-reserved,
.sapp-user-icon-moved_out,
.sapp-user-icon-moved_in,
.sapp-user-icon-transfer_to,
.sapp-user-icon-transferred {
  color: #7E8299;
  background-color: #F1F1F2;
}

/* .sapp-user-icon-transfered {
  color: #008000;
  background-color: #DEEFDE;
}

.sapp-user-icon-class_moved{
  color: #3964EA;
  background-color: #EEF6FF;
} */

.sapp-user-icon-canceled {
  color: #D35563;
  background-color: #FFF5F8;
}

.ant-select-selection-item .sapp-bg-white {
  background: #fff;
}

.sapp-padding-end-6 {
  padding-right: 6rem;
}

.sapp-width-picker-range {
  &.ant-picker-focused {
    .ant-picker-input:first-child {
      min-width: 128px;
    }

    .ant-picker-range-separator {
      padding: 0 4px;
    }
  }
}

.sapp-opacity-disable {
  opacity: 0.64;
}
