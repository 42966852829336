.sapp-title-modal-create-class {
    color: #3F4254;
    // font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 100% */
}

.sapp-field-days {
    position: absolute;
    right: 1px;
    top: 1px;
    z-index: 99999;
    background: white;
    padding: 13.5px;
    border-radius: 0px 8px 8px 0px;
    border-left: 1px solid #E1E3EA;
    background: #F9F9F9;
}

.sapp-selected-count {
    color: #FFB800;
    text-align: right;
    // font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 112.5% */
}