.end-15px {
  right: 15px !important;
}

.sapp-hook-form-text_skeleton {
  min-height: 40px !important;
}

.sapp-input-group {
  display: grid;
  grid-template-columns: min-content auto;

  input {
    width: 100% !important;
    max-height: 40px;
  }
}

.sapp-input-group-text {
  max-height: 40px;
  min-width: 40px;
}

.sapp-h-48px {
  .sapp-form-control-custom {
    padding-top: 0.845rem !important;
    padding-bottom: 0.845rem !important;
  }
}

.disabled-background {
  background-color: #F5F5F5 !important;
}

.sapp-h-48px {
  .sapp-input-group-text {
    max-height: 48px;
  }

  .sapp-input-group input {
    max-height: 48px;
  }
}

.sapp-h-70px {
  .sapp-form-control-custom {
    height: 70px !important;
  }
}

.sapp-h-70px {
  .sapp-input-group-text {
    max-height: 70px;
  }

  .sapp-input-group input {
    max-height: 70px;
  }
}

.sapp-h-70px {
  .form-control{
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    border-radius:0px !important
  }
}

.sapp-post-fix-text-field {
  color: #5E6278;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 100% */
}

.sapp-h-50px {
  .sapp-form-control-custom {
    height: 50px !important;
  }
  .btn {
    .indicator-label {
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}

.sapp-h-48px {
  .sapp-input-group-text {
    max-height: 50px;
  }

  .sapp-input-group input {
    max-height: 50px;
  }
}
