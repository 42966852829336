.list_timeline {
    margin-top: 30px;

    .list_timeline_item {
        display: flex;
        column-gap: 5px;
        align-items: center;
    }
}

.control {
    box-sizing: border-box;
    height: 16px;

    border-color: transparent transparent transparent #202020;
    transition: 100ms all ease;
    will-change: border-width;
    cursor: pointer;

    // play state
    border-style: solid;
    border-width: 8px 0 8px 12px;

    // paused state
    &.pause {
        border-style: double;
        border-width: 0px 0 0px 12px;
    }
}