.sapp-btn_add-more-50 {
  width: calc(100% - 50px);
}

.sapp-groups-answer_drag-drop {
  .input-group-text {
    min-width: 116px;
  }
}

.sapp-question_custom-container {
  max-width: calc(1320px + (5rem));
  margin: 0 auto;
  box-sizing: border-box;
}

.sapp-add-more_question-answer {
  color: var(--bs-gray-300) !important;
  border-color: var(--bs-gray-300) !important;

  i {
    color: var(--bs-gray-300) !important;
  }
}

.sapp-question_essay-drawer {
  z-index: 1100;

  .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }
}

.sapp-h-45px.ant-select-single.ant-select-lg:not(.ant-select-customize-input) {
  .ant-select-selector {
    height: 45px;
    .ant-select-selection-item {
      height: 45px;
      line-height: 45px !important;
    }
  }
  .ant-select-selection-search-input {
    height: 43px;
    line-height: 43px;
  }
  .ant-select-selection-placeholder {
    line-height: 45px;
  }
}
.sapp-h-45px {
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: 41.4px;
    }
    .ant-select-selection-search-input {
      height: 41.4px;
      line-height: 41.4px !important;
    }
    .ant-select-selection-placeholder {
      line-height: 41.4px;
    }
  }
}
.sapp-h-45px {
  .sapp-input-group-text {
    max-height: 45px;
  }
  .sapp-input-group input {
    max-height: 45px;
  }
}
.sapp-field_question-select {
  min-width: 220px;
  .ant-select-selection-item {
    padding-inline-end: 0 !important;
  }
}

.sapp-question-radio-group {
  .sapp-hook-form-radio-group > div {
    min-width: 80px;
  }
}

.sapp-accordion-header {
  .accordion-button {
    font-size: 1.075rem;
    font-weight: 600;
  }
}

.sapp-mw-425px.ant-select-single.ant-select-lg:not(.ant-select-customize-input) {
  .ant-select-selector {
    .ant-select-selection-item {
      max-width: 350px;
    }
  }
}

.card-header.sapp-question_custom-container {
  font-size: 18px !important;
  line-height: 23.4px !important;
}

.sapp-form-check-custom {
  margin-bottom: 0;
}

.sapp-mb-32px {
  margin-bottom: 32px;
}
