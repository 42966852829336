.sapp-list-staff {
  .search-input {
    font-weight: 500 !important;
  }
  .sapp-font-import-student {
    font-weight: 500 !important;
  }
  .sapp-selected-user {
    .btn.btn-danger {
      font-weight: 500 !important;
      font-size: 13px !important;
      background-color: #f8285a!important;
    }
    .fw-bolder {
      font-weight: 500 !important;
      color: #f8285a !important;
    }
  }
}

.sapp-permission-detail {
  .sapp-font-import-student.text-danger {
    color: #f8285a !important;
  }
}
