.sapp-created-content-name {
    color: #141414;
    // font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.99px; /* 114.214% */
}
.sapp-custom-bg{
    background-color: transparent !important;
}
.sapp-created-container {
    min-height: 48px;
}