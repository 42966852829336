.mock-test-type {
  &_content {
    &:hover {
      & > .mock-test-type_name {
        color: var(--bs-primary);
      }

      & > .mock-test-type_img {
        display: none;
      }

      & > .mock-test-type_img__hovered {
        display: block;
      }
    }
  }

  &_img__hovered {
    display: none;
  }

  &_name {
    text-align: center;
    color: #8f95b2;
  }
}
.sapp-table-container{
  max-width: 1570px;
  margin: auto
}
