.sapp-m-h-unset {
    max-height: unset;
}

.sapp-add-learn {
    width: 100%;
    padding: 14px;
    color: #7E8299;
    // font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 114.286% */
    border-radius: 8px;
    border: 1px solid #E1E3EA;
    cursor: pointer;
}

.sapp-header-outcome {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sapp-selected-learning-container {
    border: 1px solid #ECECEC;
    // min-height: 100px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sapp-title-checkbox-activity {
    color: var(--text-color-2nd, #A1A1A1);
    // font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.99px;
    /* 114.214% */
}

.sapp-modal-createTab.modal-fullscreen.modal-fullscreen-form {


    .modal-header {
        padding-bottom: 0;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: var(--bs-border-color);
        width: 100%;
        padding: 0 1.5rem !important
    }


}

// .sapp-custom-select2 {
//     .ant-select-selection-item .ant-select-selection-item-content {
//         white-space: nowrap;
//         max-width: 300px;
//     }
// }

.sapp-tabs-nav-container {
    max-width: calc(1200px - 3.5rem);
    margin: auto;
    width: 100%;
}

.sapp-select-custom {
    .ant-select-selector {
        height: 100% !important;
    }
    .ant-select-selection-item .ant-select-selection-item-content {
        white-space: nowrap;
        max-width: 300px;
    }
}