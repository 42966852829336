.sapp-proress-bar-wrapper {
  padding: 40px 40px 0 40px;
  margin-bottom: 40px;
}

.sapp-btn-color {
  color: var(--bs-light-inverse);
  background-color: var(--bs-light);
}

.sapp-blank-content {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .sapp-blank-content-title {
    color: var(--text-color-2nd, #a1a1a1);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 24px;
  }
}

.sapp-popup-text {
  color: #141414;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 16.8px */
}
.sapp-tree-popup-btn{
  color: var(--bs-text-gray-900);
  &:hover{
    color: var(--bs-text-primary)
  }
}
.sapp-item-tree {
  border-radius: 5px;
}