.sapp-font-import-student {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.sapp-color-import-student {
	color: #7E8299 !important;
}

.btn.btn-sapp-filter .sapp-color-import-student {
	color: #7E8299 !important;
}

.sapp-selected-user {
	.btn.btn-danger {
	  border-radius: 6px;
	  padding: 12px 16px !important;
	  font-size: 13px;
	  font-style: normal;
	  font-weight: 600;
	  line-height: 14px;
	  margin-left: 8px;
	}
}

.sapp-btn-active-search {
	padding: 12px 16px !important;
}
