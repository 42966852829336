.sapp-padding-btn-gray {
  padding: 10px 16px !important;
}

@media (min-width: 768px) {
  .sapp-table-min-height {
    min-height: 388px;
  }
}

.sapp-h-max {
  height: max-content;
}

.sapp-role-btn-icon {
  color: #252f4a !important;
  i {
    color: #252f4a !important;
  }

  &:hover {
    color: #fff !important;

    i {
      color: #fff !important;
    }
  }
}

.sapp-color-gray-role {
  color: #99a1b7;
}

.sapp-top-px {
  top: 1px;
}

@media (min-width: 1600px) {
  .sapp-3xl-space-between {
    justify-content: space-between;
  }
}

.sapp-role-table-user {
  .sapp-fs-13 {
    font-size: 13px !important;
    .ant-select-selection-search-input,
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      font-size: 13px !important;
      font-weight: 500 !important;
    }
  }

  .sapp-h-datimepicker.ant-picker {
    font-size: 13px !important;
     .ant-picker-input > input, input::placeholder{
      font-size: 13px !important;
      font-weight: 500 !important;
    }
  }
}

.sapp-add-role-label {
  label span {
    font-weight: 500 !important;
  }
}
