.box-shadow--custom {
  box-shadow: 0px 4px 62px 0px #00000012;
}

.text-left{
  text-align: left !important;
}
@media (max-width: 1368px) {
  .sapp-height-layout--login {
    overflow-y: hidden;
    height: 100vh;
  }
  .sapp-logo{
    padding-top: 50px;
  }
}
@media (min-width: 1920px) {
  .sapp-height-layout--login {
    overflow-y: hidden;
    height: 100vh;
  }
  .sapp-logo{
    padding-top: 0px;
  }
}